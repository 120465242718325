import React from 'react'
import MonitorTaskAddContainer from '../../TaskComponents/MonitorTaskAddContainer'

const OutSiderMonitorTaskAdd = () => {
  return (
    <MonitorTaskAddContainer
      taskType='101530'
      listModoleName="epidemicMoniterNotify"
      taskModuleName="epidemicMoniterTask"
      manageModuleName="epidemicMoniterTaskManage"
    />
  )
}

export default OutSiderMonitorTaskAdd
