/*
 * @Author: welson
 * @Date: 2019-08-13 10:21:42
 * @Last Modified by: Mr.Welson
 * @Last Modified time: 2019-12-30 14:57:43
 */

/**
 * 示例: 人脸图库时间选择
 * RangePicker 默认值接收一个length为2的数组 Array<Date>
 */
import React, { useState, useEffect, forwardRef } from 'react';
import moment from 'moment';
import { DatePicker } from 'antd';
import './index.less';

const { Shared } = window;
const { RangePicker } = DatePicker;

const RangePickerFilter = forwardRef(({ className, style, icon, showTime, format, dropdownClassName, ...formItemProps }, ref) => {
  const { onChange } = formItemProps;
  const [value, setValue] = useState(formItemProps.value ? formItemProps.value.map(item => moment(item)) : null);
  useEffect(() => {
    setValue(formItemProps.value ? formItemProps.value.map(item => moment(item)) : null);
  }, [formItemProps.value]);
  return (
    <div className={`lm-filter-range-picker-wrapper ${className}`} style={style}>
      <RangePicker
        ref={ref}
        showTime
        style={{ width: 424 }}
        format={format}
        value={value}
        dropdownClassName={`lm-range-picke-drop ${dropdownClassName}`}
        onChange={dates => {
          if (!dates[0]) {
            // 清空操作
            onChange(dates);
          }
          setValue(dates);
        }}
        onOk={dates => onChange(dates)}
        ranges={{
          '3天': [moment(), moment(new Date().setHours(0, 0, 0, 0)).add(3, 'd')],
          '7天': [moment(), moment(new Date().setHours(0, 0, 0, 0)).add(7, 'd')],
          '15天': [moment(), moment(new Date().setHours(0, 0, 0, 0)).add(15, 'd')]
        }}
      />
    </div>
  );
});

RangePickerFilter.defaultProps = {
  className: '',
  dropdownClassName: '',
  showTime: true,
  format: Shared.format.dataTime,
  style: {}
};

export default RangePickerFilter;
