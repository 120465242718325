import React from 'react';
import { Form } from 'antd';
import SelectUser from '../SelectUser';
import './index.less';

const TaskWeekNotifyPick = Loader.loadBaseComponent('TaskWeekNotifyPick');

export default function TasksReceive({ isNoCap, form, itemDate, privilegeName }) {
  const { getFieldDecorator } = form;
  const { repeatMode, acceptAlarmUser } = itemDate;
  return (
    <div className="monitee-tasks-box tasks-receive">
      {!isNoCap && (
        <Form.Item label="接收时间">
          {getFieldDecorator('repeatMode', {
            initialValue: repeatMode,
            rules: [
              {
                message: '请选择接收时间',
                required: true
              }
            ]
          })(<TaskWeekNotifyPick />)}
        </Form.Item>
      )}
      <Form.Item className="input-hidden-type" label="接收人员">
        {getFieldDecorator('acceptAlarmUser', {
          initialValue: acceptAlarmUser,
          rules: [
            {
              required: true
            }
          ]
        })(<SelectUser privilegeName={privilegeName} />)}
      </Form.Item>
    </div>
  );
}
