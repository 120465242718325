import React from 'react';
import { Form, InputNumber } from 'antd';
import './index.less';

function TaskRule({ form, itemDate }) {
  const { triggerDuration } = itemDate;
  const { getFieldDecorator } = form;
  return (
    <div className="task-rule-info monitee-tasks-box">
      <Form.Item label="规则设置">
        规则持续
        {getFieldDecorator('triggerDuration', {
          initialValue: triggerDuration ? Math.round(triggerDuration / (1000 * 60)) : 1,
          rules: [
            {
              message: '请设置规则触发时间',
              required: true
            },
            {
              validator: (rule, value, callback) => callback(value < 1 ? '规则时间最小为1分钟' : undefined)
            }
          ]
        })(<InputNumber placeholder="请输入布控任务名称" className="taks-name" />)}
        分钟触发提醒
      </Form.Item>
    </div>
  );
}

export default TaskRule;
