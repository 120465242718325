import React from 'react'
import { Form, Input } from 'antd'
import RangePickerFilter from '../RangePickerFilter/index'
import './index.less'

const TaskTimePartPick = Loader.loadComponent('TaskTimePartPick');

function BasicInfo({ isNoCap, form, itemDate }) {
  const { startTime, endTime, executionType, executionDay=[], executionTime=[], name, description } = itemDate
  const { getFieldDecorator } = form
  return (
    <div className="control-center-task-basic-info monitee-tasks-box">
      <Form.Item label="任务名称">
        {getFieldDecorator('name', {
          initialValue: name,
          rules: [
            {
              message: '请输入任务名称',
              required: true
            },
            {
              max: 50,
              message: `布控任务名称不超过${50}个字`
            }
          ]
        })(<Input placeholder="请输入布控任务名称" className="taks-name" />)}
      </Form.Item>

      <Form.Item label="任务有效期" className='task-validate-time-box'>
        {getFieldDecorator('times', {
          initialValue: [+startTime, +endTime],
          rules: [
            {
              message: '请选择任务执行时间',
              required: true
            }
          ]
        })(<RangePickerFilter />)}
      </Form.Item>
      {!isNoCap && (
        <Form.Item label="任务执行时间">
          {getFieldDecorator('execution', {
            initialValue: { executionType, executionDay, executionTime },
            rules: [
              {
                message: '请选择任务执行时间',
                required: true
              }
            ]
          })(<TaskTimePartPick />)}
        </Form.Item>
      )}
      <Form.Item label="任务说明">
        {getFieldDecorator('description', {
          initialValue: description,
          rules: [
            {
              max: 200,
              message: `布控任务说明不超过${200}个字`
            }
          ]
        })(<Input.TextArea placeholder="请输入任务说明文字" className="task-describe-basic" style={{ width: '80%', height: 100, resize: 'none' }} />)}
      </Form.Item>
    </div>
  )
}

export default BasicInfo
