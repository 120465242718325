import React from 'react';
import { Form } from 'antd';
import './index.less';

const TaskSelectCameras = Loader.loadBaseComponent('TaskSelectCameras');

function TasksScope({ form, itemDate }) {
  return (
    <Form.Item>
      {form.getFieldDecorator('cids', {
        initialValue: itemDate.cids,
        rules: [
          {
            validator: (_, value, callback) => {
              callback(value.length > 0 ? undefined : '请选择摄像机');
            }
          }
        ]
      })(<TaskSelectCameras points={BaseStore.device.cameraList.filter(v => v.deviceType !== Dict.map.db.value)}/>)}
    </Form.Item>
  );
}

export default React.memo(TasksScope);
