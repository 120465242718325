import React from 'react'
import { observer, inject } from 'mobx-react'
import { message, Form } from 'antd'
import moment from 'moment'
import TasksScope from '../tasksScope'
import BasicInfo from '../basicInfo'
import TasksReceive from '../tasksReceive'
import FormBtn from '../formBtn'
import TaskRule from '../taskRule'
import './index.less'

//-基本信息--布控库添加--任务范围--任务接收
const ConfirmComponent = Loader.loadBaseComponent('ConfirmComponent')

@Form.create()
@inject('tab')
@observer
class MonitorTaskAddContainer extends React.Component {
  constructor(props) {
    super(props)
    this.libType = props.libType
    this.infoModal = true
    this.state = {
      taskData: {
        type: props.taskType, //101501-黑名单 101502-未知人员布控 101503-魅影
        name: '', // 布控任务名称
        description: '', // 布控任务描述
        startTime: moment().valueOf(), // 开始时间
        endTime: moment()
          .add('days', 3)
          .valueOf(), // 结束时间
        executionType: 0,
        executionDay: [],
        executionTime: [],
        cids: [], // 布控范围
        repeatMode: null, // 重复方式
        acceptAlarmUser: [BaseStore.user.userInfo.id] // 告警接收人员
      },
      errorShow: false,
      isShowModal: false
    }
  }

  /**
   * @desc 组件数据收集
   * @param {object} obj 要修改的字段集合
   */
  changeTasksData = obj => {
    let taskData = Object.assign({}, this.state.taskData, obj)
    this.setState({ taskData })
  }

  toViewTaskList = () => {
    //跳转添加布控任务列表界面
    const { tab, taskModuleName } = this.props
    tab.goPage({
      moduleName: taskModuleName,
      isUpdate: true
    })
  }

  componentWillUnmount() {
    clearTimeout(this.setTimer)
  }

  /**
   * @desc 数据提交
   */
  onSubmit = () => {
    const { form, taskType } = this.props
    form.validateFields((err, data) => {
      try{
        if (err) {
          console.error(err)
          return
        }
        const { name, times, execution = {}, description, cids, repeatMode, acceptAlarmUser, triggerDuration } = data
        const { executionType, executionDay, executionTime } = execution
        const [startTime, endTime] = times
  
        const options = {
          type: taskType,
          name,
          executionType,
          executionDay,
          executionTime,
          description,
          cids,
          repeatMode,
          acceptAlarmUser,
          startTime: startTime * 1,
          endTime: endTime * 1,
          triggerDuration: triggerDuration * 1000 * 60
        }
        options.taskName = name
        options.tempId = '1572415561000'
        options.tempConfig = {
          cids
        }
        options.alarmAcceptUsers = acceptAlarmUser
        options.taskType = taskType
        // prettier-ignore
        Service.monitorTask.saveTask(options)
          .then(() => {
            message.success('添加成功');
            this.toViewTaskList(); // 添加成功后跳转
          })
          .catch(err => {
            message.error(err.message || '添加失败');
          });
      }catch(e){
        console.error(e)
      }
    })
  }

  cancleAddTask = () => {
    this.setState({
      isShowModal: true
    })
  }

  handleCancel = () => {
    this.setState({
      isShowModal: false
    })
  }

  render() {
    const { taskData, errorShow, isShowModal } = this.state
    const { form, taskListModuleName, taskType } = this.props
    const isNoCap = taskType === '101530'
    return (
      <div className="monitor-content-wrapper monitee-tasks-add">
        <Form autocomplete="off">
          <h2 className="header-step-title">基本信息</h2>
          <BasicInfo itemDate={taskData} changeTasksData={this.changeTasksData} form={form} libType={this.libType} isNoCap={isNoCap} />
          <h2 className="header-step-title">任务范围</h2>
          <TasksScope errorShow={errorShow} itemDate={taskData} changeTasksData={this.changeTasksData} form={form} />
          {!isNoCap && [
            <h2 className="header-step-title" key="rule-title">
              任务规则
            </h2>,
            <TaskRule errorShow={errorShow} key="rule-form" itemDate={taskData} changeTasksData={this.changeTasksData} form={form} />
          ]}
          <h2 className="header-step-title">任务接收</h2>
          <TasksReceive
            isNoCap={isNoCap}
            itemDate={taskData}
            errorShow={errorShow}
            changeTasksData={this.changeTasksData}
            form={form}
            privilegeName={taskListModuleName}
            libType={this.libType}
          />
          <FormBtn cancleSubmit={this.cancleAddTask} toSubmit={this.onSubmit} />
        </Form>
        <ConfirmComponent
          title="提示"
          visible={isShowModal}
          onCancel={this.handleCancel}
          onOk={this.toViewTaskList}
          className="monitor-tasks-model"
          img="warning"
        >
          <div className="model-content">
            <div className="title-name">确定取消操作吗?</div>
          </div>
        </ConfirmComponent>
      </div>
    )
  }
}

export default MonitorTaskAddContainer
